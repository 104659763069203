
import { defineComponent, ref } from 'vue';

import { useInformations } from '@/composables/api';
import permissionUnits from '@/components/permission-units/index.vue';

export default defineComponent({
  components: {
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const { data, isLoading, isFetching } = useInformations({ page });

    return { page, data, isLoading, isFetching };
  }
});
